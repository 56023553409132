import BottomsheetProps from '@ui/components/sheet/BottomsheetProps';
import BottomsheetWamp from '@ui/components/sheet/Bottomsheet';
import {PersonalDetailsForm} from './AuthPersonalDetails';
import React from 'react';
import {ScrollView} from 'native-base';

const CompleteProfileModal = (props: Omit<BottomsheetProps, 'children'>) => {

    return (
        <BottomsheetWamp _bottomSheetProps={{snapPoints: ['95%']}} {...props}>
            <ScrollView>
                <PersonalDetailsForm onFinish={props.onClose}/>
            </ScrollView>
        </BottomsheetWamp>
    );
};

export default CompleteProfileModal;
