import {Box, Row, Text} from 'native-base';
import DashboardCard from '@ui/features/clubhouse/components/DashboardCard';
import ParticipantAvatar from '@ui/components/participant/ParticipantAvatar';
import React from 'react';
import {User} from '@shared/models/user';
import {getScreen} from '@ui/provider/navigation/screens';
import {useRouter} from 'solito/router';
import {useTranslation} from 'react-i18next';

interface Props {
    user?: User;
}

const MyClubsCard = (props: Props) => {
    const {user} = props;
    const {t} = useTranslation();
    const {push} = useRouter();
    const clubs = user?.community?.entities ? Object.values(user.community.entities) : undefined;
    const clubsAvailable = clubs && clubs.length > 0;
    const query = clubsAvailable ? undefined : {tab: 'discoverClubs'};

    return (
        <DashboardCard onPress={() => push({pathname: getScreen('creators'), query: query})}>
            <Text color='blacks.700' variant='mediumBody'>
                {t('clubhouse.myClubs')}
            </Text>

            {clubsAvailable ? (
                <Row>
                    {clubs.slice(0, 3).map((club, index) => (
                        <Box
                            bgColor="white"
                            rounded="lg"
                            pr="0.5"
                            key={club.id}
                            position="absolute"
                            ml={index * 6}
                            zIndex={clubs.length - index}
                        >
                            <ParticipantAvatar
                                participant={club}
                                _avatar={{size: 'sm'}}
                                showName={false}
                                rounded="lg"
                            />
                        </Box>
                    ))}

                    {clubs.length > 3 && (
                        <Box flex={1} alignItems="flex-end" mt="2" mr="7">
                            <Text color='blacks.700'>
                                +{clubs.length - 3}
                            </Text>
                        </Box>
                    )}
                </Row>
            ) : (
                <Text color='blacks.500'>
                    {t('clubhouse.noClubs')}
                </Text>
            )}
        </DashboardCard>
    );
};

export default MyClubsCard;
