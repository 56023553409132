import BackgroundCard from '@ui/components/container/BackgroundCard';
import {Center} from 'native-base';
import {CircleButton} from '@ui/components/buttons/CircleButton';
import DivotsBackground from './DivotsBackground';
import IcArrowForward from '@ui/svg/IcArrowForward';
import NavigationLink from '@ui/components/buttons/NavigationLink';
import {PresenceLoadingScreen} from '@ui/components/LoadingScreen';
import React from 'react';
import WalletBalance from './WalletBalance';
import WalletButtonsRow from './WalletButtonsRow';
import {getScreen} from '@ui/provider/navigation/screens';
import {useRouter} from 'solito/router';
import {useUserWallet} from '@ui/provider/wallet';

const TopRightStyle = {top: 16, right: 16, transform: [{rotate: '-45deg'}]};

const WalletCard = () => {
    const {wallet, isWalletLoading} = useUserWallet();

    const {push} = useRouter();

    return (
        <NavigationLink hover={1} path={getScreen('wallet')} isCard>
            <BackgroundCard
                _container={{h: 240}}
                Background={<DivotsBackground showDivots='small'/>}
            >
                <PresenceLoadingScreen isLoading={isWalletLoading} flex={1} _spinner={{h: 108, size: 'sm'}}>
                    <CircleButton position="absolute" height={8} width={8} style={TopRightStyle} onPress={() => push(getScreen('wallet'))}>
                        <IcArrowForward fill='white' width={20} height={20} />
                    </CircleButton>

                    <Center flex={1}>
                        <WalletBalance balance={wallet?.balance} />

                        <WalletButtonsRow />
                    </Center>
                </PresenceLoadingScreen>
            </BackgroundCard>

        </NavigationLink>
    );
};

export default WalletCard;
