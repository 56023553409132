import {Box, Button, Column, Row, Text, useDisclose} from 'native-base';
import React, {useEffect, useState} from 'react';
import {RedeemPromoRequest, RedeemPromoResponse} from '@shared/models/promo';
import AsyncStorage from '@react-native-async-storage/async-storage';
import BottomsheetProps from '@ui/components/sheet/BottomsheetProps';
import BottomsheetWamp from '@ui/components/sheet/Bottomsheet';
import {DivotsImageLabel} from '@ui/features/wallet/DivotsLabel';
import FloatingLabelInput from '@ui/components/FloatingLabelInput';
import IcPencil from '@ui/svg/IcPencil';
import NavigationButton from '@ui/components/buttons/NavigationButton';
import {TransactionActions} from '@shared/models/wallet';
import {getScreen} from '@ui/provider/navigation/screens';
import {track} from '@ui/helpers/analytics/analytics';
import useCallable from '@ui/hooks/useCallable';
import {useRouter} from 'solito/router';
import {useTranslation} from 'react-i18next';

export const WalletEarnRedeemCoupon = () => {
    const {t} = useTranslation();
    const {isOpen, onOpen, onClose} = useDisclose();

    const onPress = () => {
        track('promo_redeem_opened', {});
        onOpen();
    };

    return (
        <Row
            bg='white'
            rounded='2xl'
            p='4'
            alignItems='center'
        >
            <Button variant='lime' w='full' onPress={onPress}>{t('wallet.promo.redeemCoupon')}</Button>

            <RedeemCouponModal isOpen={isOpen} onClose={onClose}/>
        </Row>
    );
};

interface ModalProps extends Omit<BottomsheetProps, 'children'> {
    Bottom?: JSX.Element;
}

export const RedeemCouponModal = (props: ModalProps) => {
    const {Bottom, ...restProps} = props;
    const {t} = useTranslation();
    const [promoCode, setPromoCode] = useState('');
    const {push} = useRouter();

    const {setFirstLoginRedeemCoupon} = useFirstLoginRedeemCoupon();

    const [redeemPromo, isRedeeming] = useCallable<RedeemPromoRequest, RedeemPromoResponse>('httpsRedeemPromo-default', t('general.invalid'));

    const isDisabled = promoCode.trim().length === 0;

    const onClose = () => {
        setFirstLoginRedeemCoupon();
        setPromoCode('');
        props.onClose();
    };

    const onSubmit = async() => {
        if (isDisabled || isRedeeming) {
            return;
        }

        track('promo_redeem', {promoCode});

        const response = await redeemPromo({code: promoCode});

        if (response) {
            onClose();
            const {transactionId} = response;
            push({
                pathname: getScreen('transaction'),
                query: {
                    action: TransactionActions.PROMO_REDEMPTION,
                    id: transactionId,
                },
            });
        }
    };

    return (
        <BottomsheetWamp {...restProps} onClose={onClose} _bottomSheetProps={{snapPoints: ['70%']}}>
            <Column space='2' p='4' alignItems="center" w='full' flex={1} safeAreaBottom minH={400} bg='white' roundedBottom="lg">
                <DivotsImageLabel mb='5' divots={t('wallet.divots')}/>

                <Text variant='h5' textAlign="center">{t('wallet.promo.title')}</Text>

                <Text variant='bodyLg' textAlign="center" color='blacks.600' mb='10'>{t('wallet.promo.subtitle')}</Text>

                <Box w='full' flex={1}>
                    <FloatingLabelInput
                        isRequired
                        label={t('wallet.promo.couponCode')}
                        defaultValue={promoCode}
                        onChangeText={setPromoCode}
                        returnKeyType='next'
                        maxLength={30}
                        onSubmitEditing={onSubmit}
                        blurOnSubmit={false}
                        bottomSheet
                        autoCapitalize='characters'
                        w='full'
                        alignSelf="flex-start"
                        rightElement={
                            <Box mr='2'>
                                <IcPencil fill='black' width={24} height={24}/>
                            </Box>
                        }
                    />
                </Box>

                <Button onPress={onSubmit} isDisabled={isDisabled} isLoading={isRedeeming} w='full'>
                    {t('wallet.promo.claim')}
                </Button>

                {Bottom}
            </Column>
        </BottomsheetWamp>
    );
};

const KEY = 'isFirstLoginRedeemCoupon';

export const useFirstLoginRedeemCoupon = () => {
    const [isFirstLoginRedeemCoupon, setIsFirstLoginRedeemCoupon] = React.useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    React.useEffect(() => {
        AsyncStorage.getItem(KEY).then(value => {
            setIsFirstLoginRedeemCoupon(value === null);
        })
            .finally(() => setIsLoading(false));
    }, []);

    const setFirstLoginRedeemCoupon = React.useCallback(async() => {
        await AsyncStorage.setItem(KEY, 'false');
        setIsFirstLoginRedeemCoupon(false);
    }, []);

    return {isFirstLoginRedeemCoupon, setFirstLoginRedeemCoupon, isLoading};
};

export const FirstTimeLoginRedeemCoupon = () => {
    const {isFirstLoginRedeemCoupon, isLoading} = useFirstLoginRedeemCoupon();
    const {isOpen, onClose, onOpen} = useDisclose();

    const {t} = useTranslation();

    useEffect(() => {
        if (!isLoading && isFirstLoginRedeemCoupon) {
            onOpen();
        }
    }, [isLoading, isFirstLoginRedeemCoupon]);

    return (
        <RedeemCouponModal
            isOpen={isOpen}
            onClose={onClose}
            Bottom={
                <NavigationButton
                    path={getScreen('walletEarn')}
                    variant='ghostOutline'
                    w='full'
                    onPress={onClose}
                >
                    {t('general.goEarn')}
                </NavigationButton>
            }
        />
    );
};
