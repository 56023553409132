import {Invitation, InvitationStatus, InvitationType} from '@shared/models/invitation';
import {getCollections, getDocs} from '@ui/helpers/firebase.web';
import {query, setDoc, where} from 'firebase/firestore';
import {useCollectionData, useDocumentData} from 'react-firebase-hooks/firestore';

const useInvitation = (id: string) => useDocumentData<Invitation>(
    getDocs().invitation(id),
);

const usePendingInvitations = (uid: string) => useCollectionData<Invitation>(
    query(
        getCollections().invitations,
        where('inviteeId', '==', uid),
        where('status', '==', 'PENDING'),
    ),
);

const useTeamOpenInvitation = (uid: string, teamId: string) => useCollectionData<Invitation>(
    query(
        getCollections().invitations,
        where('inviterId', '==', uid),
        where('team.id' as any, '==', teamId),
        where('status', '==', InvitationStatus.Open),
    ),
);

const useEntityOpenInvitation = (uid: string, teamId: string) => useCollectionData<Invitation>(
    query(
        getCollections().invitations,
        where('inviterId', '==', uid),
        where('entity.id' as any, '==', teamId),
        where('status', '==', InvitationStatus.Open),
    ),
);



const useAppOpenInvitation = (uid: string) => useCollectionData<Invitation>(
    query(
        getCollections().invitations,
        where('inviterId', '==', uid),
        where('type', '==', InvitationType.App),
        where('status', '==', InvitationStatus.Open),
    ),
);

const createInvitation = (invitation: Invitation) => {
    return setDoc(getDocs().invitation(invitation.id), invitation);
};

export {useInvitation, usePendingInvitations, useTeamOpenInvitation, useEntityOpenInvitation, useAppOpenInvitation, createInvitation};
