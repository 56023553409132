import {Participant, ParticipantType} from './participant';
import {EntityUiData} from './entity';
import {Fixture} from './fixture';
import {TeamUiData} from './team';
import {UserUiData} from './user';

enum InvitationType {
    Team = 'TEAM', User = 'USER', Fixture = 'FIXTURE', Entity = 'ENTITY', App = 'APP', BetTransfer = 'BET_TRANSFER',
}

enum InvitationStatus {
    Open = 'OPEN',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
}

interface BetData {
    amount: number;
}

interface Invitation {
    id: string;

    createdAt?: Date;

    // The person who is invited
    inviteeId?: string;
    invitee?: Participant;
    inviteeType: ParticipantType;
    inviteeEmail?: string;

    // The person who invited
    inviterId: string;
    inviter: Inviter;
    status: InvitationStatus;
    type: InvitationType;
    link?: string;
    // For team invitations
    team?: TeamUiData;
    // For fixture invitations
    fixture?: Fixture;
    // For entity invitations
    entity?: EntityUiData;
    // Answers for App invitations
    acceptedInviteeIds?: string[];
    acceptedInvitees?: {[id:string]: Participant};

    // For bet transfer
    betData?: BetData;
}

interface InviteTeamRequest {
    teamId: string;
    inviteeIds: string[];
}

interface InviteTeamResponse {
    ids: string[];
}

interface AnswerInvitationRequest {
    invitationId: string;
    status: InvitationAnswer;
    // For team invitations
    teamIndex?: number;
}

interface AnswerInvitationResponse {
    id: string;
}

interface CreateInviteLinkRequest {
    id: string;
    teamId?: string;
    entityId?: string;
    type: InvitationType;
    amount?: number;
}

interface CreateInviteLinkResponse {
    id: string;
    link: string;
}

interface InvitationDeleteRequest {
    invitationId: string;
}

interface InvitationDeleteResponse {
    id: string;
}

type Inviter = UserUiData;

type InvitationAnswer = InvitationStatus.Accepted | InvitationStatus.Declined;

type TeamInvitationData = Pick<Invitation, 'id' | 'inviteeId' | 'invitee' | 'inviteeType' | 'inviterId' | 'status'>;

export type {
    Invitation,
    InvitationAnswer,
    Inviter,
    InviteTeamRequest,
    InviteTeamResponse,
    AnswerInvitationRequest,
    AnswerInvitationResponse,
    CreateInviteLinkRequest,
    CreateInviteLinkResponse,
    TeamInvitationData,
    InvitationDeleteRequest,
    InvitationDeleteResponse,

};

export {InvitationType, InvitationStatus};
