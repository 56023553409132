import {ClubhouseViewScreen} from '@ui/features/clubhouse/ClubhouseView';
import React from 'react';

const ClubhouseScreen = () => {
    return (
        <ClubhouseViewScreen />
    );
};

export default ClubhouseScreen;
