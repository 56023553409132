import {Center, IPressableProps, Pressable} from 'native-base';
import React from 'react';

export const CircleButton = (props: IPressableProps) => {
    const {children,
        _hover = {bg: 'backgroundLight:alpha.30'},
        _pressed = {bg: 'backgroundLight:alpha.20'},
        bg = 'backgroundLight:alpha.10',
        ...rest
    } = props;

    return (
        <Pressable
            bg={bg}
            _pressed={_pressed}
            _hover={_hover}
            rounded='full'
            w='16'
            h='16'
            {...rest}
        >
            <Center flex={1}>
                {children}
            </Center>
        </Pressable>
    );
};

export const LimeCircleButton = (props: IPressableProps) => {
    return(
        <CircleButton
            bg='lime.default'
            _pressed={{bg: 'lime.100'}}
            _hover={{bg: 'lime.200'}}
            _disabled={{opacity: 0.4}}
            {...props}
        />
    );
};
