import {Column} from 'native-base';
import PersonalDetailsSettingsSection from './PersonalDetailsSettingsSection';
import React from 'react';

interface Props {
    onFinish?: () => void;
}

export const PersonalDetailsForm = (props: Props) => {

    return (
        <Column
            w='full'
            alignSelf='center'
            flex={1}
        >
            <PersonalDetailsSettingsSection {...props} />
        </Column>
    );
};
