import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

function IcAccount(props: SvgProps) {
    return (
        <Svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="#1C1B1F"
            {...props}
        >
            <Path
                d="M2 19.615v-2.223c0-.515.133-.975.399-1.38a2.72 2.72 0 011.066-.944c.95-.464 1.906-.823 2.867-1.075.96-.252 2.017-.378 3.168-.378 1.151 0 2.207.126 3.168.378.961.252 1.917.61 2.867 1.075.445.225.8.54 1.066.944.266.405.399.865.399 1.38v2.223H2zm17 0V17.27c0-.656-.16-1.282-.482-1.876a4.545 4.545 0 00-1.368-1.532c.67.1 1.307.255 1.91.465.602.21 1.177.457 1.725.743.516.276.915.6 1.197.975.281.374.422.782.422 1.225v2.346H19zM9.5 12c-.963 0-1.786-.343-2.472-1.028C6.343 10.286 6 9.462 6 8.5c0-.963.343-1.786 1.028-2.472C7.714 5.343 8.538 5 9.5 5c.963 0 1.786.343 2.472 1.028C12.657 6.714 13 7.538 13 8.5s-.343 1.786-1.028 2.472C11.286 11.657 10.462 12 9.5 12zm8.634-3.5c0 .962-.342 1.786-1.028 2.472-.685.685-1.509 1.028-2.472 1.028-.112 0-.256-.013-.43-.039a3.793 3.793 0 01-.431-.084c.394-.474.697-1 .91-1.578a5.193 5.193 0 00-.007-3.593 5.78 5.78 0 00-.903-1.583 1.87 1.87 0 01.43-.1c.144-.015.288-.023.431-.023.963 0 1.787.343 2.472 1.028.686.686 1.028 1.51 1.028 2.472zM3.5 18.115h12v-.723a1.01 1.01 0 00-.157-.557c-.104-.163-.27-.306-.497-.427a11.797 11.797 0 00-2.542-.965 11.5 11.5 0 00-2.804-.328 11.5 11.5 0 00-2.804.328c-.872.219-1.72.54-2.542.965-.227.121-.393.264-.497.427a1.01 1.01 0 00-.157.557v.723zm6-7.615c.55 0 1.02-.196 1.412-.588.392-.391.588-.862.588-1.412 0-.55-.196-1.02-.588-1.413A1.926 1.926 0 009.5 6.5c-.55 0-1.02.196-1.413.587A1.926 1.926 0 007.5 8.5c0 .55.196 1.02.587 1.412.392.392.863.588 1.413.588z"
            />
        </Svg>
    );
}

export default IcAccount;
