import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path, SvgProps} from 'react-native-svg';

const IcPlus = (props: SvgProps) => (
    <Svg
        width={23}
        height={23}
        fill="none"
        viewBox="0 0 23 23"
        {...props}
    >
        <G clipPath="url(#ic_plus)">
            <Path
                d="M17.25 11.978V10.65h-5.27v-5.27h-1.33v5.27H5.38v1.33h5.27v5.27h1.33v-5.27h5.27Z"
                fill={props.fill ?? '#151515'}
            />
        </G>

        <Defs>
            <ClipPath id="ic_plus">
                <Path
                    fill="#fff"
                    transform="rotate(45 5.657 13.658)"
                    d="M0 0h16v16H0z"
                />
            </ClipPath>
        </Defs>
    </Svg>
);

export default IcPlus;
