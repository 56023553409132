import * as React from 'react';
import Svg, {G, Mask, Path, SvgProps} from 'react-native-svg';
const IcMall = (props: SvgProps) => (
    <Svg
        width={32}
        height={32}
        viewBox="0 0 32 32"
        fill="none"
        {...props}
    >
        <Mask
            id="ic_mall"
            width={32}
            height={32}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
        >
            <Path fill="#D9D9D9" d="M0 0h32v32H0z" />
        </Mask>

        <G mask="url(#ic_mall)">
            <Path
                fill={props.fill ?? '#fff'}
                d="M7.077 28.666a2.322 2.322 0 0 1-1.704-.707 2.322 2.322 0 0 1-.707-1.703v-15.18c0-.665.236-1.233.707-1.704a2.322 2.322 0 0 1 1.704-.706H10c0-1.665.584-3.081 1.751-4.249C12.92 3.25 14.335 2.666 16 2.666c1.665 0 3.081.584 4.248 1.751C21.416 5.585 22 7.001 22 8.666h2.923c.665 0 1.233.235 1.704.706.47.471.706 1.04.706 1.704v15.18c0 .665-.235 1.232-.706 1.703a2.322 2.322 0 0 1-1.704.707H7.077Zm0-2h17.846a.392.392 0 0 0 .282-.128.392.392 0 0 0 .128-.282v-15.18a.392.392 0 0 0-.128-.282.392.392 0 0 0-.282-.128H7.077a.392.392 0 0 0-.282.128.392.392 0 0 0-.129.282v15.18c0 .102.043.196.129.282.085.085.18.128.282.128ZM16 17.999c1.665 0 3.081-.583 4.248-1.751C21.416 15.08 22 13.664 22 11.999h-2c0 1.111-.39 2.056-1.167 2.834-.778.777-1.722 1.166-2.833 1.166-1.111 0-2.056-.389-2.834-1.166C12.39 14.055 12 13.11 12 11.999h-2c0 1.665.584 3.081 1.751 4.249 1.168 1.168 2.584 1.751 4.249 1.751Zm-4-9.333h8c0-1.111-.39-2.056-1.167-2.833-.778-.778-1.722-1.167-2.833-1.167-1.111 0-2.056.389-2.834 1.167C12.39 6.61 12 7.555 12 8.666Z"
            />
        </G>
    </Svg>
);
export default IcMall;
