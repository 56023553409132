import {Column, Row, Text} from 'native-base';
import {CircleButton} from '@ui/components/buttons/CircleButton';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import IcMall from '@ui/svg/IcMall';
import IcPlus from '@ui/svg/IcPlus';
import IcTransfer from '@ui/svg/IcTransfer';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';
import {useRouter} from 'solito/router';
import {useTranslation} from 'react-i18next';

const WalletButtonsRow = (props: IHStackProps) => {
    const {t} = useTranslation();
    const {push} = useRouter();

    return (
        <Row alignItems="center" mt='4' w='full' maxW={300} {...props}>
            <Column alignItems="center" space='2' flex={1}>
                <CircleButton
                    bg='lime.default'
                    _pressed={{bg: 'lime.100'}}
                    _hover={{bg: 'lime.200'}}
                    onPress={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        push({pathname: getScreen('betCreate'), query: {tab: 'transfer'}});
                    }}
                >
                    <IcTransfer width={32} height={32} fill='black'/>
                </CircleButton>

                <Text color='white' variant='mediumSemiBold'>{t('wallet.transfer.shortTitle')}</Text>
            </Column>

            <Column alignItems="center" space='2' flex={1}>
                <CircleButton onPress={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    push(getScreen('walletEarn'));
                }}
                >
                    <IcPlus fill='white' width={32} height={32} />
                </CircleButton>

                <Text color='white' variant='mediumSemiBold'>{t('wallet.earn.shortTitle')}</Text>
            </Column>

            <Column alignItems="center" space='2' flex={1}>
                <CircleButton onPress={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    push(getScreen('shop'));
                }}
                >
                    <IcMall />
                </CircleButton>

                <Text color='white' variant='mediumSemiBold'>{t('cms.shop.title')}</Text>
            </Column>
        </Row>
    );
};

export default WalletButtonsRow;
