import {Box, IBoxProps} from 'native-base';
import {Pressable} from 'react-native';
import React from 'react';

interface Props extends IBoxProps {
    onPress?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _pressable?: any
}

const Card = (props: Props) => {
    const {onPress, _pressable, ...rest} = props;
    return (
        <Pressable style={_pressable} onPress={onPress} disabled={onPress === undefined}>
            <Box
                rounded="lg"
                overflow="hidden"
                borderColor='secondary.100'
                borderWidth={1}
                {...rest}
            />
        </Pressable>
    );
};

export default Card;
