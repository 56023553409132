import {Box, Button, Column} from 'native-base';
import {Fixture, VerifyFixtureResultRequest, VerifyFixtureResultResponse} from '@shared/models/fixture';
import React, {useEffect} from 'react';
import {add, differenceInMinutes} from 'date-fns';
import IcClock from '@ui/svg/IcClock';
import {SuccessToast} from '@ui/provider/toast/toastConfig';
import {getDatabaseDate} from '@shared/models/firestore-timestamp';
import {isLastScoreUploader} from '@shared/helpers/fixture-fn';
import useCallable from '@ui/hooks/useCallable';
import useCrouton from '@ui/hooks/useCrouton';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface Props {
    fixture: Fixture;
    showWarning?: boolean;
    onPress?: () => void;
}

const getHoursAndMinutesLeft = (deadline: Date) => {
    const totalMinutesLeft = differenceInMinutes(deadline, new Date());
    const hoursLeft = Math.floor(totalMinutesLeft / 60);
    const minutesLeft = totalMinutesLeft % 60;

    return [hoursLeft, minutesLeft];
};

export const VerifyWarning = (props: Props) => {
    const {fixture} = props;
    const {user} = useUser();
    const {t} = useTranslation();

    const isVerifier = !isLastScoreUploader(fixture, user!.id);

    const hasPot = fixture.pot !== undefined;
    const text = isVerifier ? t('fixture.detail.resultVerificationWarningTextUser') : t('fixture.detail.resultVerificationWarningTextOpponent');
    const text2 = hasPot ? [text, t('fixture.detail.resultVerificationWarningDivots')].join(' ') : text;

    const multiplePlayers = Object.values(fixture.participantsOrder).length > 1;

    if(!multiplePlayers) {
        return <Box mt='-4' />;
    }

    return (
        <SuccessToast
            text1={t('fixture.detail.resultVerificationWarningTitle')}
            text2={text2}
            Icon={<IcClock width={24} height={24} fill='#C1F979' />}
            flex={{base: undefined, lg: 1}}
        />
    );
};

const VerifyFixtureButton = (props: Props) => {
    const {fixture, showWarning = true, onPress} = props;
    const [verifyFixtureResult, verifyFixtureResultIsLoading] = useCallable<VerifyFixtureResultRequest, VerifyFixtureResultResponse>('fixture-verifyResult');
    const {t} = useTranslation();
    const [timeLeft, setTimeLeft] = React.useState<string | undefined>();
    const crouton = useCrouton();
    const {user} = useUser();

    const onVerify = async() => {
        const response = await verifyFixtureResult({
            fixtureId: fixture.id,
        });

        if(response && response.isSuccessful) {
            crouton.show({
                title: t('actions.success'),
                message: t('fixture.detail.resultVerificationSuccess'),
            });
        }
    };

    const setDeadlineText = (deadline: Date) => {
        const [hoursLeft, minutesLeft] = getHoursAndMinutesLeft(deadline);

        if(hoursLeft <= 0) {
            if(minutesLeft <= 0) {
                setTimeLeft(undefined);
                return;
            }
            setTimeLeft(`${minutesLeft}m left`);
            return;
        } else {
            setTimeLeft(`${hoursLeft}h and ${minutesLeft}m left`);
        }
    };

    useEffect(() => {
        if(!fixture.firstScoreUploadedAt) {
            return;
        }

        const deadline = add(getDatabaseDate(fixture.firstScoreUploadedAt), {hours: 24}); // set the deadline to 24 hours from now
        setDeadlineText(deadline);

        const intervalId = setInterval(() => {
            setDeadlineText(deadline);
        }, 60000); // update every minute

        return () => clearInterval(intervalId); // cleanup on component unmount
    }, []);


    const isVerifier = !isLastScoreUploader(fixture, user!.id);

    return (
        <Column>
            {showWarning && <VerifyWarning fixture={fixture} />}

            {isVerifier &&
            <Button
                isDisabled={verifyFixtureResultIsLoading}
                isLoading={verifyFixtureResultIsLoading}
                onPress={onPress ?? onVerify}
                mt={showWarning ? 4 : 0}
                minH={12}
            >
                {[t('fixture.detail.actions.verifyScore'), timeLeft].filter(Boolean).join(' - ')}
            </Button>
            }
        </Column>
    );
};

export default VerifyFixtureButton;
