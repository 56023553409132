import {Column, ITextProps, Text} from 'native-base';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
    _balanceText?: ITextProps;
    balance?: number;
}

const WalletBalance = (props: Props) => {
    const {balance = 0, _balanceText = {}} = props;
    const {t} = useTranslation();
    return (

        <Column alignItems="center" space='2'>
            <Text color='white' variant='boldBody' {..._balanceText}>{t('wallet.balance')}</Text>

            <Text color='white' variant='h2'>{balance.toFixed(2)}</Text>
        </Column>
    );
};

export default WalletBalance;
