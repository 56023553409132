import {Box, Circle, Column, Row, Text, useDisclose, useTheme} from 'native-base';
import Card from '@ui/components/Card';
import CompleteProfileModal from '@ui/features/auth/edit/CompleteProfileModal';
import IcAccount from '@ui/svg/IcAccount';
import IcChevronRight from '@ui/svg/IcChevronRight';
import React from 'react';
import {useTranslation} from 'react-i18next';

const CompleteProfileCard = () => {
    const {t} = useTranslation();
    const {colors} = useTheme();
    const {isOpen, onOpen, onClose} = useDisclose();

    return (
        <Card
            bgColor='darkGreen'
            onPress={onOpen}
            p={4}
            rounded='xl'
        >
            <Row
                justifyContent='space-between'
            >
                <Row
                    space={4}
                >
                    <Box
                        bgColor='lime.default'
                        p={3}
                        rounded='lg'
                    >
                        <IcAccount fill={colors.darkGreen} width={24} height={24}/>
                    </Box>

                    <Column>
                        <Text color='secondary.300'>
                            {t('clubhouse.completeProfile.title')}
                        </Text>

                        <Text color='white' variant='mediumSemiBold'>
                            {t('clubhouse.completeProfile.subtitle')}
                        </Text>
                    </Column>
                </Row>

                <Circle
                    bgColor='grey:alpha.20'
                    p={3}
                >
                    <IcChevronRight color="white"/>
                </Circle>
            </Row>

            <CompleteProfileModal isOpen={isOpen} onClose={onClose} />
        </Card>
    );
};

export default CompleteProfileCard;
