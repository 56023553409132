import {Column, Row, Text} from 'native-base';
import {Fixture} from '@shared/models/fixture';
import NavigationLink from '@ui/components/buttons/NavigationLink';
import ParticipantAvatar from '@ui/components/participant/ParticipantAvatar';
import React from 'react';
import {User} from '@shared/models/user';
import VerifyFixtureButton from '../fixture/detail/VerifyFixtureButton';
import {getOpponent} from '@shared/helpers/fixture-fn';
import {getScreen} from '@ui/provider/navigation/screens';
import {useRouter} from 'solito/router';
import {useTranslation} from 'react-i18next';

interface Props {
    fixture: Fixture;
    user: User;
}

const ClubhouseVerifyFixture = (props: Props) => {
    const {fixture, user} = props;

    const {t} = useTranslation();
    const {push} = useRouter();

    const onPress = () => {
        push({pathname: getScreen('fixture'), query: {id: fixture.id}});
    };

    const opponent = getOpponent(fixture, user.id);
    return (
        <NavigationLink path={{pathname: getScreen('fixture'), query: {id: fixture.id}}} isCard>
            <Column space={4} bg='white' w='full' rounded='2xl' overflow="hidden" p='6' h={240} >
                <Row space='4' alignItems="center" flex={1} w='full'>
                    <ParticipantAvatar showName={false} participant={opponent} _avatar={{size: 'md'}} w={undefined} />

                    <Column flex={1}>
                        <Text variant='mediumSemiBold'>{t('clubhouse.verify.matchCompleted', {username: opponent?.username})}</Text>

                        <Text variant='bodySm'>{t('clubhouse.verify.matchCompletedText')}</Text>
                    </Column>
                </Row>

                <VerifyFixtureButton fixture={fixture} showWarning={false} onPress={onPress} />
            </Column>
        </NavigationLink>
    );
};

export default ClubhouseVerifyFixture;
