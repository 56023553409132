import {Column, Text} from 'native-base';
import {FeaturedChallenges} from './FeaturedChallenges';
import {FeaturedChallengesConfig} from '@shared/models/configs';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const showFeaturedChallenges = (featuredChallenges?: FeaturedChallengesConfig) => {
    return featuredChallenges?.challenges && featuredChallenges.challenges.length > 0;
};

interface Props {
    featuredChallenges: FeaturedChallengesConfig;
}

const ClubhouseFeaturedChallenges = (props: Props) => {
    const {featuredChallenges} = props;
    const {t} = useTranslation();

    const {challengesWithLabel = []} = featuredChallenges;
    const title = featuredChallenges.title?.en ?? t('clubhouse.featuredChallenges.title');
    const description = featuredChallenges.description?.en ?? t('clubhouse.featuredChallenges.description');

    return (
        <Column space='4' w='full' p='4' bg='white' rounded='2xl' overflow="hidden">
            <Text variant='h5'>{title}</Text>

            <Text variant='body' color='blacks.700'>{description}</Text>

            <FeaturedChallenges items={challengesWithLabel} />
        </Column>
    );
};

export default ClubhouseFeaturedChallenges;
