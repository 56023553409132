import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const IcArrowOutward = (props: SvgProps) => (
    <Svg
        viewBox='0 0 24 24'
        width={24}
        height={24}
        fill="#151515"
        {...props}
    >
        <Path
            d="M6.294 17.644 5.25 16.6l9.84-9.85H6.144v-1.5h11.5v11.5h-1.5V7.804l-9.85 9.84Z"
        />
    </Svg>
);
export default IcArrowOutward;
