import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const IcClock = (props: SvgProps) => (
    <Svg
        width={20}
        height={20}
        fill="none"
        viewBox='0 0 20 20'
        {...props}
    >
        <Path
            d="M10.333 3c.997 0 1.947.193 2.851.58a7.365 7.365 0 0 1 2.339 1.563 7.364 7.364 0 0 1 1.563 2.338c.387.905.58 1.855.58 2.852 0 .997-.194 1.947-.58 2.851a7.365 7.365 0 0 1-1.563 2.339 7.366 7.366 0 0 1-2.338 1.563 7.173 7.173 0 0 1-2.852.58 7.173 7.173 0 0 1-2.852-.58 7.366 7.366 0 0 1-2.338-1.563 7.365 7.365 0 0 1-1.563-2.338A7.173 7.173 0 0 1 3 10.332c0-.997.193-1.947.58-2.852a7.364 7.364 0 0 1 1.563-2.338A7.364 7.364 0 0 1 7.481 3.58 7.173 7.173 0 0 1 10.333 3Zm0 1.333c-.813 0-1.59.16-2.33.477a6.043 6.043 0 0 0-1.915 1.279A6.024 6.024 0 0 0 4.81 8.002a5.868 5.868 0 0 0-.476 2.331c0 .812.158 1.59.476 2.33.318.742.744 1.38 1.279 1.915a6.03 6.03 0 0 0 1.913 1.278 5.86 5.86 0 0 0 2.331.476 5.86 5.86 0 0 0 2.33-.476 6.029 6.029 0 0 0 1.915-1.278 6.048 6.048 0 0 0 1.278-1.914 5.845 5.845 0 0 0 .476-2.331c0-.812-.159-1.59-.476-2.33a6.023 6.023 0 0 0-1.278-1.915 6.043 6.043 0 0 0-1.914-1.278 5.854 5.854 0 0 0-2.331-.476Zm0 1.333c.184 0 .341.066.472.196.13.13.195.287.195.471v3.724l1.807 1.803a.644.644 0 0 1 .193.474.644.644 0 0 1-.193.473.644.644 0 0 1-.473.193.644.644 0 0 1-.474-.193l-2-2a.644.644 0 0 1-.193-.473v-4c0-.184.065-.342.196-.472a.643.643 0 0 1 .471-.196h-.001Z"
            fill={props.fill ?? '#ADADAE'}
        />
    </Svg>
);

export default IcClock;
