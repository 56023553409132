import * as Linking from 'expo-linking';
import {Box, Button, Column, IButtonProps, IStackProps, Stack} from 'native-base';
import {TouchableOpacity, useWindowDimensions} from 'react-native';
import {ImageUris} from '@shared/models/image';
import React from 'react';
import WAMPImage from '@ui/components/image/WAMPImage';
import {useRouter} from 'solito/router';

interface DashboardCardLargeProps extends IStackProps{
    children?: JSX.Element[] | JSX.Element
    image?: ImageUris;
    navigationLink?: string
    buttonIcon: JSX.Element
    buttonTitle?: string
    _button?: IButtonProps
    onPress?: () => void
}

const DashboardCardLarge = (props: DashboardCardLargeProps) => {
    const {children, image, navigationLink, buttonTitle, _button, ...cardProps} = props;

    const {width} = useWindowDimensions();
    const {push} = useRouter();

    const handleNavigation = () => {
        if (navigationLink){
            if (navigationLink.includes('wamp.app')){
                const path = navigationLink.split('wamp.app')[1];
                push(path);
            } else {
                Linking.openURL(navigationLink);
            }
        }
    };

    return (
        <TouchableOpacity disabled={navigationLink === undefined} onPress={handleNavigation}>
            <Box
                rounded="xl"
                overflow='hidden'
                position='relative'
                minH={{
                    base: width * 1.1,
                    lg: width * 0.3,
                }}
                bgColor='white'
                {...cardProps}
            >
                {image &&
                <Box
                    position='absolute'
                    flex={1}
                    minH={{
                        base: width * 1.1,
                        lg: width * 0.3,
                    }}
                    w="full"
                >
                    <WAMPImage
                        src={image?.lg}
                        fill
                        contentFit='cover'
                        alt='Learn More Card'
                        image={image}
                    />
                </Box>
                }

                <Column
                    flex={1}
                    justifyContent='flex-end'
                    alignItems='flex-start'
                    space={6}
                    p={6}
                    {...cardProps}
                >
                    <Stack
                        space={6}
                        w='full'
                        direction={{
                            base: 'column-reverse',
                            lg: 'row',
                        }}
                        justifyContent='space-between'
                    >
                        {children}
                    </Stack>

                    {buttonTitle &&
                <Button
                    onPress={handleNavigation}
                    {..._button}
                >
                    {buttonTitle}
                </Button>}
                </Column>
            </Box>
        </TouchableOpacity>
    );
};

export default DashboardCardLarge;
