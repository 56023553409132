import {Button, Column, Text} from 'native-base';
import BottomsheetProps from '@ui/components/sheet/BottomsheetProps';
import BottomsheetWamp from '@ui/components/sheet/Bottomsheet';
import ParticipantAvatar from '@ui/components/participant/ParticipantAvatar';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

const WelcomeScreen = ({onPress}: {onPress: () => void}) => {
    const {user} = useUser();
    const {t} = useTranslation();

    return (
        <Column space='8' p='4' h='full' safeAreaBottom bg='white' rounded='xl'>
            <ParticipantAvatar participant={user} _avatar={{size: 'lg'}} showName={false} />

            <Text flex={1} variant='strongH4' color='black' textAlign='center' textTransform='uppercase'>{t('auth.finished.welcome', {firstName: user?.firstName})}{'\n'}

                <Text textAlign='center'>{t('auth.finished.playing')}</Text>
            </Text>

            <Button onPress={onPress}>{t('actions.letsGo')}</Button>
        </Column>
    );
};

interface Props extends Omit<BottomsheetProps, 'children'> {}

const WelcomeModal = (props: Props) => {

    return (
        <BottomsheetWamp {...props} _bottomSheetProps={{snapPoints: ['75%']}}>
            <WelcomeScreen onPress={props.onClose} />
        </BottomsheetWamp>
    );
};

export default WelcomeModal;
