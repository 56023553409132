import * as React from 'react';
import Svg, {G, Mask, Path, SvgProps} from 'react-native-svg';
const IcTransfer = (props: SvgProps) => (
    <Svg
        width={32}
        height={32}
        fill="none"
        {...props}
    >
        <Mask
            id="ic_transfer"
            width={32}
            height={32}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
        >
            <Path fill="#D9D9D9" d="M32 0v32H0V0z" />
        </Mask>

        <G mask="url(#ic_transfer)">
            <Path
                fill={props.fill ?? '#fff'}
                d="M11.218 7.164 8.14 10.24a.954.954 0 0 1-.686.283.998.998 0 0 1-.72-.283.98.98 0 0 1-.31-.713.98.98 0 0 1 .31-.713l4.64-4.638a1.103 1.103 0 0 1 .843-.341 1.103 1.103 0 0 1 .844.341l4.659 4.659c.198.198.296.43.293.696a.99.99 0 0 1-.314.709 1.045 1.045 0 0 1-.703.3c-.261.007-.495-.093-.702-.3l-3.077-3.077v8.708a.968.968 0 0 1-.287.713.968.968 0 0 1-.713.287.969.969 0 0 1-.713-.287.969.969 0 0 1-.287-.713V7.164Zm7.577 17.672v-8.708c0-.284.096-.521.287-.713a.968.968 0 0 1 .713-.287c.283 0 .521.096.713.287.19.192.287.43.287.713v8.708l3.077-3.077a.954.954 0 0 1 .686-.283.998.998 0 0 1 .719.283.98.98 0 0 1 .31.713.98.98 0 0 1-.31.713l-4.639 4.638a1.1 1.1 0 0 1-.394.264 1.28 1.28 0 0 1-.45.077c-.16 0-.31-.026-.448-.077a1.1 1.1 0 0 1-.395-.264l-4.659-4.66a.94.94 0 0 1-.293-.695.991.991 0 0 1 .314-.71c.207-.192.44-.292.702-.3.262-.006.496.094.703.3l3.077 3.078Z"
            />
        </G>
    </Svg>
);
export default IcTransfer;
