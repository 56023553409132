import {Box} from 'native-base';
import ClubhouseVerifyFixture from './ClubhouseVerifyFixture';
import InvitationsPendingCard from '../invitation/InvitationsPendingCard';
import React from 'react';
import {isLastScoreUploader} from '@shared/helpers/fixture-fn';
import {usePendingInvitations} from '../invitation/hooks/invitation-hooks';
import {useUser} from '@ui/provider/user/UserProvider';
import {useVerifiableFixtures} from './hooks/dashboard-hooks';

const ClubhouseUserActions = () => {
    const {user} = useUser();
    const [invitations, isLoading] = usePendingInvitations(user?.uid || '');
    const [fixtures, isLoadingFixtures] = useVerifiableFixtures(user?.uid || '');

    if(!user) {
        return <Box mt={-4} />;
    }

    const verifiableFixtures = fixtures?.filter((fixture) => !isLastScoreUploader(fixture, user.id));

    if(isLoading || isLoadingFixtures || (!invitations?.length && !verifiableFixtures?.length)) {
        return <Box mt={-4} />;
    }

    if(verifiableFixtures?.length) {
        return <ClubhouseVerifyFixture fixture={verifiableFixtures[0]} user={user}/>;
    }

    return (
        <InvitationsPendingCard invitations={invitations!} />
    );
};

export default ClubhouseUserActions;
