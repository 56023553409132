import {Column, Row, Text, useBreakpointValue} from 'native-base';
import {Invitation, InvitationType} from '@shared/models/invitation';
import Card from '@ui/components/Card';
import IcChevronRight from '@ui/svg/IcChevronRight';
import NavigationButton from '@ui/components/buttons/NavigationButton';
import NavigationLink from '@ui/components/buttons/NavigationLink';
import ParticipantAvatar from '@ui/components/participant/ParticipantAvatar';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';
import {useTranslation} from 'react-i18next';

interface Props {
    invitations: Invitation[];
}

const InvitationsPendingCard = (props: Props) => {
    const {invitations} = props;
    const {t} = useTranslation();

    const invitation = invitations[0];

    const fontVariant = useBreakpointValue({base: 'mediumSemiBold', lg: 'h4'});
    const avatarSize = useBreakpointValue({base: 'md', lg: 'lg'});

    if(invitation.type === InvitationType.Fixture) {
        const participant = invitation.inviter;

        return (
            <Column justifyContent='space-between' alignItems="center" bg='white' rounded='2xl' p={{base: 4, lg: '6'}} space='2' flex={1}>
                <ParticipantAvatar participant={participant} _avatar={{size: avatarSize}} showName={false}/>

                <Text
                    variant={fontVariant}
                    color='black'
                    textAlign="center"
                    w='full'
                >
                    {t('invitation.pending.fixture.challengesYou', {username: participant.username})}
                </Text>

                <NavigationButton
                    variant='lime'
                    path={{
                        pathname: getScreen('invitationsDetail'),
                        query: {
                            id: invitation.id,
                        },
                    }}
                    w='full'
                >
                    {t('actions.view')}
                </NavigationButton>
            </Column>
        );
    }

    return (
        <NavigationLink path={{
            pathname: getScreen('invitationsDetail'),
            query: {
                id: invitation.id,
            },
        }}
        >
            <Card p='4' bg='darkGreen' h={{base: undefined, lg: 240}} alignItems="center" justifyContent="center">
                <Row justifyContent='space-between'>
                    <Text variant='medium' color='white'>{t('invitation.pendingInvitations')}</Text>

                    <IcChevronRight color='white'/>
                </Row>
            </Card>
        </NavigationLink>
    );
};

export default InvitationsPendingCard;
