import {Box, IBoxProps} from 'native-base';
import React from 'react';
import {StyleSheet} from 'react-native';

interface Props {
    _container: IBoxProps;
    Background?: React.ReactNode;
    children?: React.ReactNode;
}

const BackgroundCard = (props: Props) => {
    const {_container, Background, children} = props;
    return (
        <Box
            w='full'
            rounded='2xl'
            overflow='hidden'
            {..._container}
        >
            {Background}

            <Box
                flex={1}
                style={StyleSheet.absoluteFill}
            >
                {children}
            </Box>
        </Box>
    );
};

export default BackgroundCard;
