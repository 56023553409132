import {Column, IStackProps} from 'native-base';
import React from 'react';
import {useWindowDimensions} from 'react-native';

const AuthWrapperColumn = (props: IStackProps) => {
    const {height} = useWindowDimensions();
    return (
        <Column
            flex={{
                base: 1,
                lg: 'none',
            }}
            bg='white'
            h={{
                base: 'full',
                lg: height - 220,
            }}
            w={{
                base: 'full',
                lg: '640px',
            }}
            p='4'
            safeAreaBottom
            alignSelf='center'
            my={{
                base: '0',
                lg: '4',
            }}
            {...props}
        />
    );
};


export default AuthWrapperColumn;
