import {IButtonProps} from 'native-base';
import IcArrowOutward from '@ui/svg/IcArrowOutward';
import NavigationButton from '@ui/components/buttons/NavigationButton';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';
import useColors from '@ui/hooks/native-base/use-colors';
import {useTranslation} from 'react-i18next';

export const PlayBetButton = (props: IButtonProps) => {
    const {t} = useTranslation();
    const colors = useColors();
    return (
        <NavigationButton
            path={getScreen('betCreate')}
            variant="primary"
            endIcon={<IcArrowOutward fill={colors.lime.default} />}
            py='5'
            bg="darkGreen"
            {...props}
        >
            {t('clubhouse.startBet')}
        </NavigationButton>
    );
};
