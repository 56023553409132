import {Box, IBoxProps} from 'native-base';
import DivotImage from './DivotImage';
import React from 'react';
import {StyleSheet} from 'react-native';

interface DivotsBackgroundProps extends IBoxProps {
    showDivots?: 'large' | 'small';
    gradientDirection?: 'horizontal' | 'vertical';
    showGradient?: boolean;
    _leftDivots?: IBoxProps;
    _middleDivots?: IBoxProps;
    _rightDivots?: IBoxProps;
}

const DivotsBackground = (props: DivotsBackgroundProps) => {
    const {
        showDivots,
        _middleDivots = {},
        _rightDivots = {},
        _leftDivots = {},
        ...rest
    } = props;

    return (
        <Box style={StyleSheet.absoluteFill} {...rest} bg='darkGreen'>

            {showDivots &&
            <>
                <Box
                    position="absolute"
                    left={showDivots === 'small' ? -55 : '10%'}
                    top={showDivots === 'small' ? -55 : '10%'}
                    {..._leftDivots}
                >
                    <DivotImage />
                </Box>

                <Box
                    position="absolute"
                    left={showDivots === 'small' ? -55 : '10%'}
                    bottom={showDivots === 'small' ? -55 : '10%'}
                    style={{transform: [{scaleX: -1}]}}
                    {..._middleDivots}
                >
                    <DivotImage />
                </Box>

                <Box
                    position="absolute"
                    right={showDivots === 'small' ? -55 : '10%'}
                    top={showDivots === 'small' ? 55 : '50%'}
                    {..._rightDivots}
                >
                    <DivotImage />
                </Box>
            </>
            }

        </Box>
    );
};

export default DivotsBackground;
