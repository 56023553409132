import {Column, Text, useTheme} from 'native-base';
import DashboardCardLarge from '@ui/features/clubhouse/components/engagementCards/DashboardCardLarge';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import IcArrowForward from '@ui/svg/IcArrowForward';
import {NewsItem} from '@shared/models/newsItem';
import React from 'react';

interface Props extends IHStackProps {
    item: NewsItem;
}

const NewsItemCard = (props: Props) => {
    const {item, ...rest} = props;

    const {colors} = useTheme();

    const {banner: image, buttonText, link, title, description, imageText} = item;

    return (
        <Column space={4} {...rest}>
            {title && <Text variant='h5'>{title.en}</Text>}

            {description && <Text variant='bodyLg' color='blacks.600' mb={2}>{description.en}</Text>}

            <DashboardCardLarge
                image={image}
                navigationLink={link}
                buttonTitle={buttonText?.en}
                buttonIcon={
                    <IcArrowForward
                        fill={colors.blacks[500]}
                        width={20}
                        height={20}
                    />
                }
                _button={{
                    variant: 'white',
                }}
            >
                {imageText &&
                <Text color='white' variant='strongH2'>
                    {imageText.en}
                </Text>
                }
            </DashboardCardLarge>
        </Column>
    );
};

export default NewsItemCard;
